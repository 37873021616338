<template>
  <div class="">
    <div class="fixed top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12"></div>
    <div class="px-14 mt-4">
      <SlideUp>
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
          style="height: calc(100vh - 175px)"
        >
          <div class="rounded-t mb-0 px-4 py-3 border-0">
            <div class="flex flex-wrap items-center">
              <div
                class="relative w-full px-4 max-w-full flex-grow flex-1 flex items-center"
              >
                <h3 class="font-semibold text-lg text-blueGray-700">
                  {{ $t("permissions") }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </SlideUp>
    </div>
  </div>
</template>

<script>
import SlideUp from "@/components/transitions/SlideUp.vue";

export default {
  name: "Tickets",
  components: {
    SlideUp
  },
  data() {
    return {};
  },
  methods: {}
};
</script>
